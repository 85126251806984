import { FocusEventHandler, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'ui-kit-v2/icon/icon';
import TextInputComponent from 'ui-kit-v2/text-input/text-input';

import { BREAKPOINTS } from 'const/breakpoints';

import useWindowDimensions from 'hooks/useWindowDimensions';

import { SetupAccountInputPasswordProps } from './setup-account-input-password.props';
import './setup-account-input-password.style.scss';

export const SetupAccountInputPassword = ({
    label,
    onChange,
    onBlur,
    isTouched,
    variant,
    ...props
}: SetupAccountInputPasswordProps) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [password, setPassword] = useState('');
    const [isPasswordContainerVisible, setPasswordContainerVisible] = useState(false);
    const { width } = useWindowDimensions();
    const { t } = useTranslation();

    const checkMinLength = password.length >= 8;
    const checkNoIdenticalChars = !/^(?!.*(.)\1{2,})/.test(password);
    const checkUpperCase = /[A-Z]/.test(password);
    const checkLowerCase = /[a-z]/.test(password);
    const checkNumber = /\d/.test(password);
    const checkSpecialChar = /[!@#$%&*]/.test(password);
    const validConditions = [checkUpperCase, checkLowerCase, checkNumber, checkSpecialChar].filter(Boolean).length;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
        onChange?.(event);
    };

    const handleFocus = () => {
        if (width >= BREAKPOINTS.xl) {
            setPasswordContainerVisible(true);
        }
    };

    const handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
        if (width >= BREAKPOINTS.xl) {
            setPasswordContainerVisible(false);
        }
        onBlur?.(event);
    };

    const handleMouseEnter = () => {
        if (width >= BREAKPOINTS.xl) {
            setPasswordContainerVisible(true);
        }
    };

    const handleMouseLeave = () => {
        if (width >= BREAKPOINTS.xl && !inputRef.current?.matches(':focus')) {
            setPasswordContainerVisible(false);
        }
    };

    useEffect(() => {
        if (width <= BREAKPOINTS.xl) {
            setPasswordContainerVisible(true);
        } else {
            setPasswordContainerVisible(false);
        }
    }, [width]);

    return (
        <div className="setup-account-input-password" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <TextInputComponent
                {...props}
                ref={inputRef}
                label={label}
                variant={variant}
                type={isPasswordVisible ? 'text' : 'password'}
                contentRight={{
                    children: isPasswordVisible ? <Icon icon="eye" /> : <Icon icon="eye-closed" />,
                    onClick: () => setIsPasswordVisible(!isPasswordVisible)
                }}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onChange={handleChange}
            />

            {isPasswordContainerVisible && (
                <div className="setup-account-input-password__input-container">
                    <ul className="setup-account-input-password__list">
                        <li
                            className={`setup-account-input-password__list-item ${
                                checkMinLength ? 'setup-account-input-password__list-item--valid' : ''
                            }`}
                        >
                            <span className="setup-account-input-password__list-item__icon">
                                {checkMinLength ? (
                                    <Icon viewBox="-2 -4 24 24" icon="small-success" />
                                ) : (
                                    <>
                                        {isTouched ? (
                                            <Icon viewBox="-4 -4.5 24 24" icon="small-alert" color="deep-chestnut" />
                                        ) : (
                                            <Icon viewBox="-4 -4.5 24 24" icon="empty-circle" color="gainsboro" />
                                        )}
                                    </>
                                )}
                            </span>
                            {t('setupYourAccount.errors.password.minLength')}
                        </li>
                        <li
                            className={`setup-account-input-password__list-item ${
                                !checkNoIdenticalChars && password.length >= 2
                                    ? 'setup-account-input-password__list-item--valid'
                                    : ''
                            }`}
                        >
                            <span className="setup-account-input-password__list-item__icon">
                                {!checkNoIdenticalChars && password.length >= 2 ? (
                                    <Icon viewBox="-2 -4 24 24" icon="small-success" />
                                ) : (
                                    <>
                                        {isTouched ? (
                                            <Icon viewBox="-4 -4.5 24 24" icon="small-alert" color="deep-chestnut" />
                                        ) : (
                                            <Icon viewBox="-4 -4.5 24 24" icon="empty-circle" color="gainsboro" />
                                        )}
                                    </>
                                )}
                            </span>
                            {t('setupYourAccount.errors.password.noMoreThanTwoIdentical')}
                        </li>
                        <li
                            className={`setup-account-input-password__list-item ${
                                validConditions >= 3 ? 'setup-account-input-password__list-item--valid' : ''
                            }`}
                        >
                            <span className="setup-account-input-password__list-item__icon">
                                {validConditions >= 3 ? (
                                    <Icon viewBox="-2 -4 24 24" icon="small-success" />
                                ) : (
                                    <>
                                        {isTouched ? (
                                            <Icon viewBox="-4 -4.5 24 24" icon="small-alert" color="deep-chestnut" />
                                        ) : (
                                            <Icon viewBox="-4 -4.5 24 24" icon="empty-circle" color="gainsboro" />
                                        )}
                                    </>
                                )}
                            </span>
                            {t('setupYourAccount.errors.password.atLeastThreeFollowingValid')}
                        </li>
                        <ul className="setup-account-input-password__sub-list">
                            <li
                                className={`setup-account-input-password__sub-list-item ${
                                    checkUpperCase ? 'setup-account-input-password__sub-list-item--valid' : ''
                                }`}
                            >
                                <span className="setup-account-input-password__sub-list-item__icon">
                                    {checkUpperCase ? (
                                        <Icon viewBox="-2 -4 24 24" icon="small-success" />
                                    ) : (
                                        <>
                                            {isTouched ? (
                                                <Icon
                                                    viewBox="-4 -4.5 24 24"
                                                    icon="small-alert"
                                                    color="deep-chestnut"
                                                />
                                            ) : (
                                                <Icon viewBox="-4 -4.5 24 24" icon="empty-circle" color="gainsboro" />
                                            )}
                                        </>
                                    )}
                                </span>
                                {t('setupYourAccount.errors.password.uppercase')}
                            </li>
                            <li
                                className={`setup-account-input-password__sub-list-item ${
                                    checkLowerCase ? 'setup-account-input-password__sub-list-item--valid' : ''
                                }`}
                            >
                                <span className="setup-account-input-password__sub-list-item__icon">
                                    {checkLowerCase ? (
                                        <Icon viewBox="-2 -4 24 24" icon="small-success" />
                                    ) : (
                                        <>
                                            {isTouched ? (
                                                <Icon
                                                    viewBox="-4 -4.5 24 24"
                                                    icon="small-alert"
                                                    color="deep-chestnut"
                                                />
                                            ) : (
                                                <Icon viewBox="-4 -4.5 24 24" icon="empty-circle" color="gainsboro" />
                                            )}
                                        </>
                                    )}
                                </span>
                                {t('setupYourAccount.errors.password.lowercase')}
                            </li>
                            <li
                                className={`setup-account-input-password__sub-list-item ${
                                    checkNumber ? 'setup-account-input-password__sub-list-item--valid' : ''
                                }`}
                            >
                                <span className="setup-account-input-password__sub-list-item__icon">
                                    {checkNumber ? (
                                        <Icon viewBox="-2 -4 24 24" icon="small-success" />
                                    ) : (
                                        <>
                                            {isTouched ? (
                                                <Icon
                                                    viewBox="-4 -4.5 24 24"
                                                    icon="small-alert"
                                                    color="deep-chestnut"
                                                />
                                            ) : (
                                                <Icon viewBox="-4 -4.5 24 24" icon="empty-circle" color="gainsboro" />
                                            )}
                                        </>
                                    )}
                                </span>
                                {t('setupYourAccount.errors.password.number')}
                            </li>
                            <li
                                className={`setup-account-input-password__sub-list-item ${
                                    checkSpecialChar ? 'setup-account-input-password__sub-list-item--valid' : ''
                                }`}
                            >
                                <span className="setup-account-input-password__sub-list-item__icon">
                                    {checkSpecialChar ? (
                                        <Icon viewBox="-2 -4 24 24" icon="small-success" />
                                    ) : (
                                        <>
                                            {isTouched ? (
                                                <Icon
                                                    viewBox="-4 -4.5 24 24"
                                                    icon="small-alert"
                                                    color="deep-chestnut"
                                                />
                                            ) : (
                                                <Icon viewBox="-4 -4.5 24 24" icon="empty-circle" color="gainsboro" />
                                            )}
                                        </>
                                    )}
                                </span>
                                {t('setupYourAccount.errors.password.specialCharacter')}
                            </li>
                        </ul>
                    </ul>
                </div>
            )}
        </div>
    );
};
